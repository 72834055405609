.circularWrap {
  position: relative;

  .circularBackground {
    position: absolute;
  }

  .circular {
    color: var(--button-bg-2);
    animation-duration: 550ms;
  }
}

.infoStep {
  width: 64%;
  position: absolute;
  top: 24%;
  left: 18%;
  .step {
    text-align: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: var(--text-color);
    padding-bottom: 7px;
  }

  .numberStep {
    text-align: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 14px;
    color: var(--text-color);
  }

  .checkIcon {
    font-size: 29px;
    position: absolute;
    left: 11px;
    top: 5px;
    color: var(--text-color);
  }
}
