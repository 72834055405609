[data-theme="dark"] {
  --main-bg-color: #2c2d31;
  --inverted-bg-color: #ffffff;
  --main-bg-color-secondary: #444348;
  --main-bg-inverted-color: #2e2e2e;
  --main-auth-color: #444348;

  --warning-bg: #553b3d;

  --modal-main-text: #ffffff;

  --border-color-1: #3f3d44;
  --border-color-btn: #ffffff;

  --informing-bg-1: #4169e1;
  --informing-bg-2: #1db960;
  --informing-bg-3: #ee392e;
  --informing-bg-4: #0782f3;

  --icon-color-1: #bdbdbd;
  --icon-color-2: #ffffff;
  --icon-color-3: #e0e0e0;

  --text-color: #bdbdbd;
  --text-color-1: #ffffff;
  --text-color-2: #9e9e9e;
  --text-color-3: #ffffff;
  --text-color-4: #bdbdbd;
  --text-color-5: #9e9e9e;
  --inverted-text-color: #212121;
  --text-disable-color: #9e9e9e;

  --placeholder-color-1: #bdbdbd;

  --button-bg: #5ab667;
  --button-text-color-1: #616161;
  --button-text-color-disabled: #616161;
  --button-color-disabled: #616161;
  --button-bg-2: #ff1940;
  --button-bg-3: #4169e1;
  --button-bg-4: #1db960;

  --border-color-1: #39373e;

  --button-start-work-bg: #ff1940;
  --button-stop-work-bg: #4169e1;
  --button-start-work-text-color: #ffffff;
  --button-stop-work-text-color: #212121;
  --button-gps: #0782f3;

  --order-label-sum: #4169e1;
  --order-label-distance: #ffffff;
  --order-label-tariff: #1db960;
  --order-label-time: #eeeeee;
  --order-text-sum: #2c2d31;
  --order-text-distance: #2c2d31;
  --order-text-tariff: #ffffff;
  --order-text-time: #2c2d31;

  --order-main-bg-color: #2c2d31;
  --order-detail-address-bg: #444348;
  --order-detail-address-text: #ffffff;

  --bg-inspectation-car: #6b4242;

  --error-bg-input: #6b4242;

  --popup-circle: #414042;

  --error-bg-input: #6b4242;
  --default-bg-input: #eeeeee;

  --pin-1: #ffffff;

  --search-input-1: #444348;
  --marker-bottom-color: rgba(7, 130, 243, 0.1);

  --alert-color-1: #ffde17;

  // skeleton
  --skeleton-color: #fff;
  --skeleton-shadow: rgba(0 0 0 / 20%);

  // progress-bar
  --present-color: #1db960;
  --empty-color: #808080;

  // table colors
  --primary-table-color: #444348;
  --secondary-table-color: #494949;
  --primary-border-color: #494949;
  --secondary-border-color: #494949;

  // text color
  --accent-text-color: #a1a1a1;

  // shadow
  --primary-shadow: 0 6px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.1);
}
