.input_block {
  position: relative;
  .Input {
    border: none;
    outline: none;
    background-color: var(--main-bg-color-secondary);
    border-radius: 12px;
    text-indent: 14px;
    color: var(--text-color-1);
  }

  .Input-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .Input-icon-close {
      width: 20px;
      height: 20px;
      path {
        stroke: var(--inverted-bg-color);
      }
    }
  }
}
