$button-background-color: #ff9308;
$text-color: #fff;
$zindex-background: 1;
$zindex-text: 2;

.cancelOrderButton {
  margin: 10px 16px 0 16px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $button-background-color;
    transform: translateX(-100%);
    animation: fillButton var(--animation-duration) linear forwards;
    z-index: $zindex-background;
  }

  .buttonText {
    position: relative;
    z-index: $zindex-text;
    color: $text-color;
  }
}

.canCancel {
  background-color: $button-background-color;
}

@keyframes fillButton {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
