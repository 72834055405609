.defaultInput {
  border: none;
  outline: none;
  background-color: var(--default-bg-input);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  justify-content: center;

  fieldset {
    border: none;
  }
}
