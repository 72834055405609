.otherNavigationWrap {
  position: absolute;
  bottom: 25px;
  right: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 165px;
}

.navListMainWrap {
  opacity: 0;
  transform: translateY(30px);
  z-index: -1;
  transition: all 0.2s linear;

  &Open {
    opacity: 1;
    transform: translateY(-8px);
    z-index: 101;
  }
}

.otherNavigationButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-bg-color);
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50px;
  box-shadow: 0 6px 10px 4px #00000026;
  margin: 0 0 0 auto;
  padding: 0;
  z-index: 101;
  user-select: none;
  -webkit-user-select: none;

  a {
    width: 100%;
    height: 100%;
    display: flex;
  }

  img {
    width: 100%;
    height: 100%;
    display: flex;
  }
}

.chosenNavButton {
  pointer-events: none;
}

.navImage,
.navCloseImage {
  padding: 0;
  display: flex;
}

.navButton {
  background-color: transparent;
  border: none;
}

.navImage {
  path {
    fill: var(--inverted-bg-color);
    stroke: var(--main-bg-color);
  }
}

.navCloseImage {
  path {
    fill: var(--main-bg-color);
    stroke: var(--inverted-bg-color);
  }
}
