.OrderControlsWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.control-element {
  background-color: #ffffff;
  border-radius: 100px;
  width: 43%;
  justify-content: center;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15);
}

.category-controls {
  display: flex;
  align-items: center;

  .list-icon {
    padding: 10px 7px 10px 13px;
    min-width: 18px;
    height: 20px;
  }

  p {
    font-family: "Roboto";
    font-weight: 200;
    font-size: 15px;
    line-height: 18px;
    padding-right: 20px;
    color: #212121;
  }
}

.radius-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 390px) {
  .control-element {
    width: 48%;
  }
}
