.destinationPoints {
  width: 100%;
  background-color: var(--order-detail-address-bg);
  padding: 20px;
  color: var(--modal-main-text);
  align-items: center;
  border-radius: 20px;
}

.destinationPoint {
  padding: 5px 0;
  border-bottom: 1px solid var(--border-color-1);
  display: flex;
  column-gap: 20px;
  font-size: 14px;
  align-items: center;

  p {
    width: 100%;
  }
}

.addressTextBlock {
  .mainText {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 3px;
  }

  .secondaryText {
    font-size: 14px;
    color: var(--text-color);
  }
}

.destinationImageWrap {
  display: block;
  margin-right: 2px;

  path {
    fill: var(--inverted-bg-color);
  }
}

.destinationPointLast {
  border-bottom: none;
  margin-left: -3px;
}

.pointNumBlack {
  background-color: var(--inverted-bg-color);
  border-radius: 50%;
  min-width: 18px;
  height: 18px;
  margin-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  font-size: 13px;
}

.pointNumFirst {
  .innerPointNumBlack {
    width: 9px;
    height: 8px;
    background-color: var(--main-bg-color);
  }
}

.innerPointNumBlack {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-bg-color);
}
