.EtherOrderMap {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  width: 100%;
  
  .map {
    opacity: 1;
  }

  .showMap {
    opacity: 0;
  }
}

.mapModal {
  height: 73vh;
}

.loader {
  width: 30px;
}

.openMapButton {

  background-color: var(--informing-bg-1);
  color: var(--text-color-1);

  span {
    font-size: 15px;
  }
}
