//white
$white: #ffff;

//green
$green-200: #1db960;

//yellow
$yellow-500: #ffde17;

// black
$black-900: #212121;

// gray
$gray-300: #eeeeee;

// red
$red-400: #ff1940;
$red-500: #ee392e;

// pink
$pink-400: #ffb6c1;
