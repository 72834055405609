.modal {
  display: flex;
  align-items: end;
  justify-content: center;

  [class~="MuiPaper-root"] {
    position: absolute;
    background-color: var(--main-bg-color);
    width: 93%;
    margin: 0;
    border-radius: 28px;
  }
}

.modalBottom {
  [class~="MuiPaper-root"] {
    bottom: 40px;
  }
}

.noPadding {
  [class~="MuiDialogContent-root"] {
    padding: 0;
  }
}
