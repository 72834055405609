@import "src/styles/colors/colors.scss";

.modalOrderWrapper {
  position: relative;
  z-index: 1000;
  width: 100%;
  display: flex;
  min-height: 60vh;
  transition: all 0.2s linear;

  * {
    box-sizing: border-box;
  }
}

.modalOrderHeader {
  display: flex;
  flex-direction: column;
  background-color: var(--order-main-bg-color);
  width: 100%;
  border-radius: 20px 20px 0 0;
}

.modalOrderIncomeOrder {
  display: flex;
  overflow-y: scroll;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  transition: all 0.3s linear;
  padding: 0 20px;
}

.modalOrderOpen {
  min-height: 90vh;

  .checkpoint {
    max-height: max-content;
    height: max-content;
    transition: all 0.3s linear;
  }
}

.destinationPoints {
  width: 100%;
  background-color: var(--order-detail-address-bg);
  padding: 20px;
  color: var(--modal-main-text);
  align-items: center;
  border-radius: 20px;
}

.destinationPoint {
  padding: 5px 0;
  border-bottom: 1px solid var(--border-color-1);
  display: flex;
  column-gap: 20px;
  font-size: 14px;
  align-items: center;

  p {
    width: 100%;
  }
}

.addressTextBlock {
  .mainText {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 3px;
  }

  .secondaryText {
    font-size: 14px;
    color: var(--text-color);
  }
}

.destinationImageWrap {
  display: block;
  margin-right: 2px;

  path {
    fill: var(--inverted-bg-color);
  }
}

.destinationPointLast {
  border-bottom: none;
  margin-left: -3px;
}

.lastItemOrder {
  min-width: 25px;
  margin-right: 3px;
}

.pointNumBlack {
  background-color: var(--inverted-bg-color);
  border-radius: 50%;
  min-width: 18px;
  height: 18px;
  margin-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  font-size: 13px;
}

.pointNumFirst {
  .innerPointNumBlack {
    width: 9px;
    height: 8px;
    background-color: var(--main-bg-color);
  }
}

.innerPointNumBlack {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-bg-color);
}

.buttonsWrap {
  width: 100%;
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.confirmButton,
.cancelButton {
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  color: $white;
}

.confirmButton {
  background-color: var(--button-start-work-bg);
  border: 1px solid transparent;
}

.cancelButton {
  background-color: transparent;
  color: var(--text-color-1);
  width: 100%;
  border: 1px solid var(--inverted-bg-color);
}

.disabledButton {
  opacity: 0.5;
}

.prefsIcon {
  path {
    fill: #ffde17;
  }
}
