.container {
	width: 100%;
	height: 2px;
	background-color: #444348;
}

.progressBar {
	height: 100%;
	background-color: #ffffff;
	transition: width 1s linear;
}
