@import "src/styles/colors/colors.scss";

.confirmButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 8px 0px;
  line-height: 19px;
  margin-top: auto;
  margin-bottom: 0;

  button {
    border-radius: 8px;
    height: 56px;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
  }

  .confirm {
    background-color: var(--button-start-work-bg);
    margin-bottom: 15px;
  }

  .cancel {
    background-color: transparent;
    border-color: var(--inverted-bg-color);
    color: var(--text-color-1);
    width: 100%;
  }

  .synced {
    width: calc(100% - 32px);
    background-color: $red-400;
    border-radius: 8px;
    color: #ffffff;
    border: none;
  }
}

.buttonsWrap {
  width: 100%;
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.confirmButton,
.cancelButton {
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  color: $white;
}

.confirmButton {
  background-color: var(--button-start-work-bg);
  border: 1px solid transparent;
}

.preOrderButton {
  background-color: $pink-400;
}

.cancelButton {
  background-color: transparent;
  color: var(--text-color-1);
  width: 100%;
  border: 1px solid var(--inverted-bg-color);
}

.disabledButton {
  opacity: 0.5;
}
