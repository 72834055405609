.container {
  position: absolute;
  width: 91%;
  bottom: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.atWork {
    bottom: 166px;
  }

  .containerLeft,
  .containerRigth {
    display: flex;
    align-items: center;
    gap: 15px;
    height: auto;
  }
}

.ordersFilterByPolygons {}

.autoAcceptDisabled {}