.acceptCoordsSnackBar {
  [class~="MuiPaper-root"] {
    background-color: var(--button-bg-4);
  }

  [class~="MuiSnackbarContent-message"] {
    display: none;
  }

  [class~="MuiPaper-root"] {
    padding: 16px;
    display: flex;
    justify-content: center;
  }

  [class~="MuiSnackbarContent-action"] {
    padding: 0;
    margin: 0;
  }
}

.acceptCoordsSnackBarContent {
  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    width: 25px;
    height: 25px;
  }
}

.manualCoordsWrapBtn {
  z-index: 106;
}

.mainViewWrapClass,
.manualCoordsWrapBtn {
  z-index: -1;
  opacity: 0;
  transition: all 0.3s linear;

  &Active {
    opacity: 1;
    z-index: 1000;
  }
}

.manualCoordsWrapBtn {
  background-color: var(--inverted-bg-color);
  color: var(--main-bg-color);
  border: none;
  padding: 10px;
  border-radius: 25%;
  font-size: 16px;
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-weight: 500;
/*   bottom: 250px;
  right: 16px; */
}

.greenRound {
  display: flex;
  width: 12px;
  height: 12px;
  background-color: var(--button-bg-4);
  border-radius: 50%;
}

.questionSymbol {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--main-bg-color);
}

.warningImg {
  width: 28px;
  path {
    fill: var(--informing-bg-1);
  }
}

.gpsIconRed {
  path{
    fill: var(--button-bg-2);
  }
}

.gpsIconGreen {
  path{
    fill: var(--button-bg-4);
  }
}
