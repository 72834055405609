.returnButtonWrap {
  background-color: var(--main-bg-color);
  width: 44px;
  height: 44px;
  border-radius: 16px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.returnButtonIcon {
  path {
    stroke: var(--inverted-bg-color);
  }
}
