@import "src/styles/colors/colors.scss";

.finalOrderMenuWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--order-detail-address-bg);
  padding: 15px;
  color: var(--modal-main-text);
  border-radius: 20px;
  font-size: 14px;
  row-gap: 10px; 

  .finalOrderRow {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    width: 100%;

    h2 {
      font-size: 22px;
      font-weight: normal;
      margin: 0;
      color: var(--modal-main-text);
    }

    .finalPrice,
    .companyReimburses {
      font-size: 22px;
      font-weight: bold;
    }
  }
}

.creditCard {
  path {
    fill: var(--inverted-bg-color);
  }

  circle {
    stroke: var(--inverted-bg-color);
  }
}



.finalOrderPriceBonuses {
  display: flex;
  justify-content: center;

  align-items: center;
  column-gap: 8px;
  opacity: 0.6;
  font-size: 20px;
}

.finalOrderPriceBonusesItem {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  width: 100%;
}

.finalOrderPriceBonusesInner {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;

  span:nth-child(2) {
    font-weight: 700;
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: $gray-300;
  margin: 10px 0;
}

.finalOrderBonusesDetails {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
  opacity: 0.6;

  li {
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    span:nth-child(2) {
      font-weight: 700;
    }
  }
}