.PageLayoutWrapper {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);
  display: flex;
  flex-direction: column;
  font-family: "Roboto";
  font-style: normal;
  padding: 0 16px;
}
