.progressBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  box-sizing:border-box;
}

.circlesContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 80%;
  margin-top: 25px;
}

.circlesContainer p{
  font-family: "Roboto";
  font-weight: 700;
  font-size: 17px;
}

.circleWrapper {
  position: absolute;
  display: flex;
  align-items: center;
}

.circle {
  border-radius: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: relative;
  background-color: var(--text-color-2);
  color: black;
  z-index: 2;
}

.circle.active {
  background-color: var(--informing-bg-1);
  color: var(--text-color-3);
}

.circle.current {
  background-color: var(--button-bg-4);
  color: var(--text-color-3);
  z-index: 10;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  left: -5px;
}

.circle.inactive {
  background-color: var(--text-color-2);
  color: var(--text-color-3);
}

.line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); 
  height: 3px;
  z-index: 1;
  background-color: var(--informing-bg-1);
}

.lineActive {
  background-color: var(--informing-bg-1);
}

.lineInactive {
  background-color: var(--text-color-2);
}

.hasSkippedAutoAssignment{
  background-color: var(--button-bg-2) !important;
}
