.suggestionList {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);
  border-radius: 16px;
  height: 100%;
  row-gap: 10px;
  padding: 16px;
  overflow-y: auto;
  max-height: calc(100svh - 280px);

  li {
    padding: 10px 0;
  }

  li:not(:last-child) {
    border-bottom: 1px solid var(--search-input-1);
  }
}

@media (max-height: 480px) {
  .suggestionList {
    max-height: calc(100svh - 235px);
  }
}

@media (max-height: 300px) {
  .suggestionList {
    max-height: calc(100svh - 180px);
  }
}
