@import "reset";
@import "themes/dark";
@import "themes/light";
@import "colors/colors";
@import "variables/global";

* {
  font-family: "Roboto";
  text-size-adjust: none !important;
}

html,
body {
  min-height: 100vh;
  min-height: 100%;
  overflow-x: hidden;
  margin: 0px;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Robotoregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Robotomedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Robotobold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
