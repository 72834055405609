.AddNewCar {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.withoutPadding {
  padding: 0;
}

.setCarInfo {
  position: relative;
}

.setCarInfo {
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    padding: 0 18px;
    color: var(--text-color);
  }
  .btnSelect {
    background-color: var(--main-bg-color-secondary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 7px 18px 7px 18px;
    margin-bottom: 20px;
    .selectItem {
      color: var(--placeholder-color-1);
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
    .active {
      color: #fff;
    }

    .downIcon {
      path {
        stroke: var(--inverted-bg-color);
      }
      padding-top: 10px;
      color: #bdbdbd;
    }

    .move_right {
      margin-left: 13px;
    }
  }

  .car_type {
    display: flex;
    justify-content: center;
    // justify-content: space-between;
  }

  .numberAuto {
    width: 100%;
    padding: 16px 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--inverted-bg-color);
    &::placeholder {
      color: var(--placeholder-color-1);
    }
  }

  .class-auto_block {
    padding-bottom: 15px;
  }
}

.carType {
  display: flex;
  justify-content: space-between;
  .setCarInfo {
    min-width: 48.5%;
  }
}

.confirmBtn {
  margin-bottom: 20px;
  margin-top: auto;
  background-color: #ff1940;
  color: #ffffff;
}

.disabled {
  opacity: 0.5;
}
