.EtherOrderAddress {
  padding-bottom: 2px;
  margin-top: 5px;
}

.orderListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}

.orderDetails {
  display: flex;
  align-items: center;
  flex: 1;
}

.distanceContainer {
  display: flex;
  flex-direction: column;
  margin-right: 10px;

  .distanceContainer {
    p {
      font-size: 13px;
      letter-spacing: 0.25px;
      font-weight: 400;
      color: var(--text-color-1);
      margin-left: 5px;
      white-space: nowrap;
    }
  }
}

.imageContainer {
  display: flex;
  margin-right: 7px;
}

.addressText {
  .mainAddress {
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 0.5px;
  }

  .secondaryAddress {
    font-family: "Roboto";
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.25px;
    color: var(--text-color);
  }
}
