.autoAcceptPopup {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
  
    h2 {
      font-size: 24px;
      font-weight: 700;
      color: var(--inverted-bg-color);
    }
  
    .autoAcceptDescription {
      font-size: 16px;
      font-weight: 300;
      color: var(--inverted-bg-color);
    }
  
    .autoAcceptAttempt {
      font-size: 20px;
      font-weight: 400;
    }
  }
  
  .warningImg {
    path {
      fill: var(--alert-color-1);
    }
  }
  
  .alertPopupWrap {
    z-index: -1;
    opacity: 0;
    transition: all 0.2s linear;
  
    &Active {
      opacity: 1;
      z-index: 1005;
    }
  }
  
  .newModalContentClassName {
    margin: auto 20px 30px;
  }
  