.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.controlContainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 6px;
    background: var(--text-disable-color);
    border-radius: 2px;
    outline: none;
    transition: background 0.3s;
    cursor: pointer;
    position: relative;
    --slider-color: var(--button-bg-3);
}

.slider.disabled {
    --slider-color: var(--text-disable-color);
}

.slider::-webkit-slider-runnable-track {
    height: 6px;
    border-radius: 2px;
    background: var(--text-disable-color);
    position: relative;
}

.slider::-moz-range-track {
    height: 6px;
    border-radius: 2px;
    background: var(--text-disable-color);
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: var(--slider-color);
    border-radius: 4px;
    cursor: pointer;
    margin-top: -4px;
    position: relative;
    z-index: 2;
}

.slider::-moz-range-progress {
    background: var(--slider-color);
    height: 6px;
    border-radius: 2px;
}

.slider::before {
    content: "";
    position: absolute;
    height: 6px;
    background: var(--slider-color);
    border-radius: 2px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: var(--progress-width, 0%);
}

.slider[data-value]::before {
    width: calc(var(--progress-width, 0%) * 1%);
}

.value {
    font-size: 25px;
    font-weight: 800;
    color: var(--text-color-1);
}

.value.disabled {
    color: var(--text-disable-color);
}
