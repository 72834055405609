.addressTextBlock {
  .mainText {
    font-size: 18px;
    font-weight: 700;
		padding-bottom: 3px;
  }

  .secondaryText {
    font-size: 14px;
    color: var(--text-color);
  }
}