.pageContainer {
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

.showShadow {
  z-index: 1;
  background-color: var(--main-bg-color);
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: var(--primary-shadow);
  border-radius: 0 0 15px 15px;
}
