.iconStroke {
  path {
    stroke: var(--icon-color-2);
  }
  circle {
    stroke: var(--icon-color-2);
  }
}

.iconFill {
  path {
    fill: var(--icon-color-2);
  }
  circle {
    stroke: var(--icon-color-2);
  }
}

.invertedStroke {
  path {
    stroke: var(--inverted-bg-color);
  }
  circle {
    stroke: var(--icon-color-2);
  }
}

.invertedFill {
  path {
    fill: var(--inverted-bg-color);
  }
  circle {
    stroke: var(--icon-color-2);
  }
}
