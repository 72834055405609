@import "src/styles/colors/colors.scss";

.container {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  .circle {
    width: 12px;
    height: 12px;
    background-color: $green-200;
    border-radius: 50%;
    display: inline-block;
    animation: blink 1s infinite;
  }
}

.infoOrder,
.infoDrivers {
  margin: 0 5px;
}

.title,
.count {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.25px;

  &.count {
    font-weight: 800;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
