.text {
  letter-spacing: 0.5px;
}

.primary {
  color: var(--text-color-1);
}

.secondary {
  color: var(--text-color);
}

.inverted {
  color: var(--inverted-text-color-1);
}

.error {
  color: var(--text-error-color);
}

.success {
  color: var(--text-success-color);
}

.failure {
  color: var(--text-error-color);
}

.disable {
  color: var(--text-disable-color);
}

.accent {
  color: var(--accent-text-color);
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.text12 {
  font: var(--font-s);
}

.text15 {
  font: var(--font-m);
}

.text16 {
  font: var(--font-l);
}

.text18 {
  font: var(--font-xl);
}

.text24 {
  font: var(--font-xxl);
}

.weight-light {
  font-weight: 300;
}

.weight-medium {
  font-weight: 500;
}

.weight-large {
  font-weight: 700;
}
