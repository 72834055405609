@import "src/styles/mixins/mixin.scss";

.mainManualCoordsView {
	@include reset-block-and-inner;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100svh;
  width: 100%;
  height: 100%;
  background-color: (--main-bg-color);
  display: flex;
}

.returnButtonWrap {
  position: absolute;
  top: 0;
  left: 14px;
  z-index: 101;
}

.mapWrap {
  width: 100%;
  height: 100%;
  display: flex;
}

.manualCoordsSearchComponent {
  position: fixed;
  bottom: 40px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  row-gap: 10px;
  z-index: 101;
}

.customSearchComponentWrap {
  background-color: var(--main-bg-color);
  width: 100%;
  padding: 16px;
  display: flex;
}

@media (max-height: 480px) {
  .manualCoordsSearchComponent {
    bottom: 5px;
    row-gap: 5px;
  }
}
