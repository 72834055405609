.navListWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--main-bg-color);
  row-gap: 8px;
  border-radius: 8px;

  * {
    box-sizing: border-box;
  }
}

.nawList {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--main-bg-color);
  row-gap: 8px;
  padding: 8px;

  a {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--main-bg-color);
    color: var(--inverted-bg-color);
  }
}

.formControlLabelWrap {
  border-top: 1px solid var(--border-color-1);
  padding: 8px;
  width: 100%;

  [class~="MuiFormControlLabel-root"] {
    display: flex;
    column-gap: 4px;
    margin: 0 auto 0 0;
  }

  [class~="MuiCheckbox-root"] {
    padding: 0;
  }
}

.navListCheckboxLabel {
  width: 100%;
  [class~="MuiFormControlLabel-label"] {
    font-size: 14px;
		color: var(--text-color-1);
  }
}

.navListCheckbox {
  color: var(--inverted-bg-color);

  &[class~="MuiCheckbox-root"] {
    color: var(--inverted-bg-color);
  }

  &[class~="Mui-checked"] {
    color: var(--informing-bg-4);
  }
}
