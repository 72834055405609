.mainInfo {
  margin-top: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.backIcon {
  .arrowleftIcon {
    path {
      fill: #fff;
    }
  }
}

.infoBlock {
  margin-top: 100px;
}

.check {
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoFinish {
  h1 {
    padding-top: 24px;
    color: var(--inverted-bg-color);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
  h3 {
    padding-top: 8px;
    color: var(--text-color);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding-left: 6px;
  }
}

.appBtn {
  margin-top: auto;
  margin-bottom: 60px;
  button {
    color: #ffffff;
    background-color: #4169e1;
    width: 100%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
}

.help {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  .phoneIcon {
    color: var(--inverted-bg-color);
    font-size: 20px;
    padding-right: 10px;
  }
  p {
    text-decoration: underline;
    color: var(--inverted-bg-color);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
  }
}
