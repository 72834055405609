.etherServingAuto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.leftSide {
  display: flex;
  align-items: center;

  .icon {
    margin-right: 8px;
    path {
      fill: var(--icon-color-2);
    }
  }

  span {
    font-family: "Roboto";
    font-size: 16px;
    letter-spacing: 0.25px;
  }
}

.time {
  font-weight: 700;
}
