:root {
  --font-family-main: "Roboto", serif;

  // s
  --font-size-s: 12px;
  --font-line-s: 16px;
  --font-s: var(--font-size-s) / var(--font-line-s) var(--font-family-main);

  // m
  --font-size-m: 15px;
  --font-line-m: 20px;
  --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);

  // l
  --font-size-l: 16px;
  --font-line-l: 20px;
  --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);

  // xl
  --font-size-xl: 18px;
  --font-line-xl: 24px;
  --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);

  // xxl
  --font-size-xxl: 24px;
  --font-line-xxl: 30px;
  --font-xxl: var(--font-size-xxl) / var(--font-line-xxl) var(--font-family-main);
}
