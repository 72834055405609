.EtherOrdersDataControl,
.sortingClassCar {
	display: flex;
	align-items: center;
	padding: 0 16px;
}

.sortingClassCar {
	margin-bottom: 10px;
}

.leftSide {
	display: flex;
	align-items: center;
	margin-right: auto;
}

.rightSide {
	display: flex;
	align-items: center;
}

.sortIcon {
	cursor: pointer;
	margin: 0 5px;

	path {
		fill: #fff;
	}
}

.sortByEfficiencyAsc,
.sortByDistanceAsc,
.sortByDistanceDesc,
.sortByPriceAsc,
.sortByPriceDesc {
	path {
		stroke: #EE392E;
		fill: #EE392E;
	}
}