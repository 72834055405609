.addressNotFoundPopup {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;

  h2 {
    font-size: 24px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    font-weight: 300;
  }
}

.alertPopupWrap {
  z-index: -1;
  opacity: 0;
  transition: all 0.3s linear;

  &Active {
    opacity: 1;
    z-index: 2006;
  }
}
