@import "src/styles/colors/colors.scss";

.orderPriceBlock {
  display: flex;
  flex-direction: column;
  background-color: var(--order-detail-address-bg);
  padding: 20px;
  color: var(--modal-main-text);
  border-radius: 20px;
}

.orderPrice,
.orderPriceBonusesItem {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 14px;
}

.orderPriceInner {
  display: flex;
  width: 100%;
  justify-content: space-between;

  span:nth-child(2) {
    font-weight: 700;
  }
}

.fullOrderTotalQuote {
  display: flex;
  align-items: center;
  gap: 10px;
}

.greyOrderLine {
  width: 100%;
  height: 1px;
  background-color: $gray-300;
  opacity: 0.1;
  margin: 10px 0;
}

.orderPriceBonuses {
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
  align-items: center;
}

.orderPriceBonusesItem {
  display: flex;
  align-items: center;
  opacity: 0.6;
}

.orderPriceBonusesInner {
  display: flex;
  align-items: center;
  column-gap: 5px;

  span:nth-child(2) {
    font-weight: 700;
  }
}

.walletIcon {
  path {
    &:last-child {
      stroke: var(--icon-color-2);
    }
  }
}
