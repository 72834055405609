.modalconfirm {
  background: rgba(33, 33, 33, 0.8);
  width: 100%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  z-index: 1005;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  //  z-index: 10001;
  .modalconfirm-section {
    background-color: var(--main-bg-color);

    border: none;
    position: absolute;
    border-radius: 28px;
    top: calc(50% - 306px / 2 - 60px);
    width: 85%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .confirm-text {
      padding: 25px 20px 0 20px;
      text-align: center;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: var(--modal-main-text);
    }

    .confirm-buttons {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
      margin-bottom: 10px;
      padding: 30px 0;
      button {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        border: none;
        background: inherit;
        color: var(--text-color-1);
      }
    }
  }
}
