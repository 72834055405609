@import "src/styles/colors/colors.scss";

.blackButton {
  width: 100%;
  background-color: var(--button-stop-work-bg);
  color: $white;
  font-size: 18px;
  border: 1px solid transparent;
  padding: 18px;
  border-radius: 10px;

  &:active {
    opacity: 0.9;
  }
}

.blackButtonDisabled {
  opacity: 0.7;
}