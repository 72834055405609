@import "src/styles/colors/colors.scss";

.EtherOrderItem {
  background-color: var(--main-bg-color-secondary);
  border-radius: 16px;
  transition:
    background-color 0.3s,
    transform 0.07s;
  margin-bottom: 12px;

  &:first-child {
    margin-top: 5px;
  }

  &:last-child {
    margin-bottom: 60px;
  }
}

.container {
  padding: 7px 11px;
  border-radius: 10px;
  p {
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
    white-space: pre-wrap;
  }
}

.etherComment {
  display: flex;
  gap: 10px;
}

.etherCommentIcon {
  width: 100%;
  max-width: 20px;

  path {
    fill: var(--inverted-bg-color);
  }
}

.btn {
  display: flex;
  justify-content: center;
  color: var(--text-color-1);
  transition: background-color 0.3s ease;
  padding: 11px 0;
  margin-top: 10px;

  .icon {
    height: 24px;
    path {
      stroke: var(--icon-color-2);
    }
  }
}

.cancel {
  background-color: var(--button-bg-2);
}

.accept {
  background-color: var(--button-bg-4);
}

.preOrder {
  background-color: $pink-400;
}

.swipeable {
  will-change: transform;
}

.swipeLeft {
  background-color: #5d0a18;
  transform: translateX(-100%);
}

.swipeRight {
  background-color: #0c3f22;
  transform: translateX(100%);
}

.OrderAddress {
  margin-top: 16px;
}

.economyHighlighting {
  border: 1px solid #FEC600;
  box-shadow: 0 0 6px 3px rgba(254, 198, 0, 1), 0 0 6px 1px rgba(254, 198, 0, 0.8);
}

.standardHighlighting {
  border: 1px solid #0782F3;
  box-shadow: 0 0 6px 3px rgba(7, 130, 243, 1), 0 0 6px 1px rgba(7, 130, 243, 0.8);
}

.comfortHighlighting {
  border: 1px solid #1DB960;
  box-shadow: 0 0 6px 3px rgba(29, 185, 96, 1), 0 0 6px 1px rgba(29, 185, 96, 0.8);
}

.highlightClassName {
  border: 1px solid #EE392E;
  box-shadow: 0 0 6px 3px rgba(238, 57, 46, 1), 0 0 6px 1px rgba(238, 57, 46, 0.8);
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    border-color: #EE392E;
    box-shadow: 0 0 6px 3px rgba(238, 57, 46, 1), 0 0 6px 1px rgba(238, 57, 46, 0.8);
  }
  50% {
    border-color: transparent;
    box-shadow: 0 0 6px 3px rgba(238, 57, 46, 0.3), 0 0 6px 1px rgba(238, 57, 46, 0.3);
  }
  100% {
    border-color: #EE392E;
    box-shadow: 0 0 6px 3px rgba(238, 57, 46, 1), 0 0 6px 1px rgba(238, 57, 46, 0.8);
  }
}

.economyHighlighting,
.standardHighlighting,
.comfortHighlighting
{
  border-radius: 11px;
}

.etherPreferences {
  margin: 10px 0 0 2px;
}
.prefs_list {
  display: flex;
}

.pref_icon {
  transform: scaleY(-1);
  display: flex; 
  align-items: center;
  justify-content: center;
  width: 35px;
  margin-right: 10px;
  margin-left: -6px;
  path {
    fill: var(--inverted-bg-color);
    stroke: var(--inverted-bg-color);
  }
}