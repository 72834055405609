@import "src/styles/colors/colors.scss";

.order-section {
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //  font-family: "Roboto";
  font-style: normal;
  font-family: "Roboto";
  font-style: normal;

  .navigation-button {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--main-bg-color);
    width: 136px;
    height: 40px;
    border: none;
    border-radius: 50px;
    bottom: 0;
    right: 0;
    margin-right: 16px;
    box-shadow: 0 6px 10px 4px #00000026;
    margin-bottom: 26px;
    z-index: 100;

    .nav-path1 {
      fill: var(--inverted-bg-color);
    }

    .nav-path2 {
      fill: var(--main-bg-color);
    }

    .nav-path3 {
      stroke: var(--main-bg-color);
    }

    .navigation-text {
      font-weight: 600;
      color: var(--order-detail-address-text);
      font-size: 14px;
      line-height: 24px;
    }
  }

  .order-header {
    width: 100%;
    height: 51px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    background: #ffde17;
    color: #212121;
    border-radius: 16px 16px 0px 0px;

    &-pause {
      @extend .order-header;
      background: #0782f3;
      color: #ffffff;
    }

    &-complete {
      @extend .order-header;
      background: #1db960;
      color: #ffffff;
    }

    .text {
      display: inline-block;
      white-space: nowrap;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 16px 0 16px;
    }

    .order-icon {
      display: inline-block;
      transform: translate(10%, 18%);
    }

    .order-icon-closed {
      transform: translate(11%, 25%);
    }

    .icon-svg {
      width: 26px;
      height: 26px;
      margin-right: 16px;
      fill: var(--order-detail-address-text);
    }

    .icon-white {
      width: 26px;
      height: 26px;
      margin-right: 16px;
    }

    .icon-white-pause {
      width: 26px;
      height: 26px;
      margin-right: 16px;
      path {
        fill: var(--main-bg-color);
      }

      path {
        fill: #ffffff;
      }
    }
  }

  .top-nav {
    background-color: #fea523;
    padding: 11px 0 11px 0;

    display: flex;
    justify-content: space-between;
    color: #fff;

    .FaBars {
      margin-left: 10px;
      height: 7vw;
      width: 7vw;
      align-self: center;
    }

    p {
      margin: 0px;
    }

    .order-info {
      display: flex;
      flex-direction: row;

      .profile-icon {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        border-radius: 50px;
        width: 16px;
      }

      .customer-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
        font-size: 4vw;
      }
    }

    .icon {
      background-color: green;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-self: center;
      justify-content: center;
      margin-right: 10px;

      a {
        color: #fff;
        text-decoration: none;
        display: flex;

        .FaPhone {
          width: 25px;
          height: 25px;
          align-self: center;
        }

        .FaMapMarked {
          width: 25px;
          height: 25px;
          align-self: center;
        }
      }
    }
  }
  .bottom-section {
    margin-bottom: 80px;
    position: relative;
    background-color: var(--main-bg-color);

    .FaAngleDoubleDown {
      position: absolute;
      width: 10vw;
      left: 50%;
      margin: 6px 0 0 -5vw;
      // background-color: #fff;
      // border-radius: 50%;
      // border: 2px solid #000;
      // margin-top: -15px;
    }

    .hidden {
      display: none !important;
    }

    p {
      margin: 0px;
    }

    .checkpoint {
      background-color: var(--order-main-bg-color);
      max-height: 250px;
      overflow-y: scroll;
      box-sizing: border-box;
      padding: 0 20px;

      .order-time-count {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 8px;
        column-gap: 8px;

        .order-time-count-single {
          display: grid;
          background: var(--order-detail-address-bg);
          color: var(--order-detail-address-text);
          border-radius: 16px;
          align-content: space-between;
          padding: 16px;
          width: 100%;
          row-gap: 5px;

          .order-time-text {
            font-size: 14px;
            font-weight: 400;
          }

          .order-time-text-count {
            font-size: 24px;
            margin-bottom: -5px;
            font-weight: 700;
          }
        }
      }

      .minus-result {
        font-size: 24px;
        margin-bottom: -5px;
        font-weight: 700;
        color: $red-400;
      }

      .order-info {
        //margin: 0px 16px 0px 16px;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: var(--order-detail-address-text);
        letter-spacing: 0.5px;

        .order-info-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          padding: 16px;
          background: var(--order-detail-address-bg);
          border-radius: 16px;
          margin-top: 8px;

          .map-pin-button {
            width: 32px;
            position: absolute;
            right: 0;
            top: 0;
            background-color: var(--order-main-bg-color);
            margin-top: 16px;
            margin-right: 16px;
            height: 32px;
            border: none;
            border-radius: 50px;

            .down-arrow-style {
              path {
                stroke: var(--inverted-bg-color);
              }
            }
          }

          .map-pin-circle {
            width: 10px;
            position: absolute;
            left: 0;
            margin-top: -13px;
            padding-left: 23px;
            height: 10px;

            .map-circle-color {
              fill: var(--order-detail-address-bg);
              stroke: #bdbdbd;
            }
          }

          .address {
            display: flex;
            flex-direction: row;
            width: 100%;

            .black {
              fill: var(--order-detail-address-text);
            }

            .grey-dark {
              color: #bdbdbd;
              fill: #bdbdbd;
            }

            .grey-light {
              color: #9e9e9e;
              fill: #9e9e9e;
            }

            .address-row {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-weight: 400;
              font-size: 16px;
              letter-spacing: 0.5px;
              width: 100%;

              .address-text-regular {
                margin-left: 10px;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
              }

              .address-text {
                margin-left: 10px;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
              }

              .address-text-pin {
                border-bottom: 1.5px solid var(--border-color-1);
                margin-left: 10px;
                padding-bottom: 10px;
                padding-right: 30px;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                text-overflow: ellipsis;
                width: 100%;

                &.borderHidden {
                  border: none;
                  padding-bottom: 9px;
                }

                .address-text-secondary {
                  font-size: 14px;
                  font-weight: 400;
                  display: flex;
                  color: #9e9e9e;
                }
              }

              .icon-item-pin {
                width: 34px;
                height: 34px;
                padding-bottom: 10px;
                position: relative;

                .path2 {
                  fill: var(--order-detail-address-bg);
                }
              }

              .point-num {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                font-weight: 700;
              }

              .blackPoint,
              .grayPoint {
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 10;
              }

              .innerBlackPoint {
                width: 60%;
                height: 60%;
              }

              .blackPoint {
                background-color: var(--inverted-bg-color);

                .innerBlackPoint {
                  background-color: var(--main-bg-color);
                  color: var(--inverted-bg-color);
                }
              }

              .grayPoint {
                background-color: var(--main-bg-color-secondary);
                // border: 1px solid var(--inverted-bg-color);

                .innerBlackPoint {
                  background-color: var(--inverted-bg-color);
                  color: var(--main-bg-color-secondary);
                }
              }

              .icon-item {
                width: 22px;
                height: 22px;

                .icon-svg {
                  path {
                    fill: var(--main-bg-color);
                  }

                  path {
                    fill: var(--order-detail-address-text);
                  }

                  circle {
                    stroke: var(--order-detail-address-text);
                  }
                }
              }

              .order-info {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;

                .profile-icon {
                  min-width: 40px;
                  min-height: 40px;
                  max-width: 40px;
                  max-height: 40px;
                  border-radius: 50px;
                }

                .customer-info {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  font-size: 4vw;

                  .circleAvatar {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }
              }

              .icon {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-self: center;
                justify-content: center;

                a {
                  color: #fff;
                  text-decoration: none;
                  display: flex;

                  .FaPhone {
                    align-self: center;
                  }

                  .FaMapMarked {
                    width: 25px;
                    height: 25px;
                    align-self: center;
                  }
                }
              }
            }
          }

          .profile-img {
            width: 24px;
            height: 24px;
            border-radius: 100px;
          }

          .client-row {
            width: 100%;
            display: flex;
            flex-direction: row;

            .address-text {
              margin-left: 5px;
            }
          }

          &-additional {
            @extend .order-info-item;
            flex-direction: row;
          }
        }

        .client-info {
          display: flex;
          flex-direction: row;

          justify-content: space-evenly;

          .client-name {
            font-size: 4vw;

            align-self: center;
          }

          .profile-img {
            min-width: 40px;
            min-height: 40px;
            max-width: 40px;
            max-height: 40px;
            border: 3px solid #fea523;
            border-radius: 50%;
          }
        }

        .point-text {
          color: #fea523;
        }

        .comment {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: 10px 10px 10px 10px;
        }

        .order-payment {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

          .payment-line {
            display: flex;
            flex-direction: row;

            p {
              margin: 10px 10px 10px 0px;
            }

            .line-block {
              display: flex;
              flex-direction: row;

              p {
                margin-bottom: 5px;
              }
            }

            .cash {
              border-bottom: 2px solid green;
            }

            .bonus {
              border-bottom: 2px solid #fea523;
            }

            .pref-item {
              margin-right: 5px;
            }
          }

          .side {
            justify-content: space-evenly;
          }
        }
      }

      // padding: 10px 10px 10px 10px;
      font-size: 4vw;

      .trip-info {
        display: flex;
        flex-direction: column;

        .point-text {
          color: #fea523;
          margin-right: 10px;
        }

        .address-list {
          list-style-type: none;
          margin: 0px;
          padding: 0px;

          .address {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;

            .address-row {
              display: flex;
              flex-direction: row;
            }
          }
        }

        .payment-line {
          display: flex;
          flex-direction: row;

          p {
            margin: 0px 10px 10px 0px;
          }

          .line-block {
            display: flex;
            flex-direction: row;

            p {
              margin-bottom: 5px;
            }
          }

          .pref-item {
            margin-right: 5px;
          }
        }

        .side {
          justify-content: space-evenly;
        }
      }
    }

    .bottom-nav {
      //  background-color: #fea523;
      background-color: var(--order-main-bg-color);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 10px 0 10px 0;
      //color: #fff;
      //   font-size: 4vw;

      text-align: center;

      .status-cancelled,
      .status {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        // width: 328px;
        padding: 17px 0;
        font-weight: 700;
        font-size: 16px;
        background-color: var(--button-start-work-bg);
        color: #ffffff;
        margin: 0 16px 0 16px;
        border: 1px solid transparent;
      }

      .status-cancelled {
        margin-top: 7px;
        background-color: #ee392e;
      }

      .disabled_btn {
        pointer-events: none;
        opacity: 0.5;
      }

      .disabled_btn {
        pointer-events: none;
        opacity: 0.5;
      }

      .disabled_btn {
        pointer-events: none;
        opacity: 0.5;
      }

      .cancel,
      .resume {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid;
        border-radius: 8px;
        // width: 328px;
        padding: 17px 0;
        font-weight: 700;
        font-size: 16px;
        background-color: var(--order-main-bg-color);
        border-color: var(--inverted-bg-color);
        color: var(--text-color-1);
        margin: 8px 16px 0 16px;
      }

      .resume {
        background-color: var(--button-bg-2);
        border-color: var(--button-bg-2);
      }

      .FaTimes {
        margin-right: 10px;
        height: 7vw;
        width: 7vw;
      }
    }
  }
}
