.Button {
  // max-width: 300px;
  font-family: "Roboto";
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border-radius: 12px;
  padding: 15px 0px;
  border: none;
  outline: none;
  background-color: var(--button-bg);
}
