@import "src/styles/colors/colors.scss";

$slideBallColor: white;

@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.work-btn {
  @include center;
  position: relative;
  width: 91%;
  height: 56px;
  position: fixed;
  bottom: 94px;
  border-radius: 8px;
  border: 0;
  z-index: 100;
  background-color: var(--button-stop-work-bg);
  color: var(--button-stop-work-text-color);

  span {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: $white;
  }
}

.slider {
  transition:
    width 0.3s,
    border-radius 0.3s,
    height 0.3s;
  position: absolute;
  left: 4px;
  background-color: $slideBallColor;
  width: 50px;
  height: 50px;
  border-radius: 12px;
  @include center;
  &.unlocked {
    transition: all 0.3s;
    width: inherit;
    left: 0 !important;
    height: inherit;
    border-radius: inherit;
  }
}

.disabled-btn {
  background-color: var(--button-color-disabled);
  color: var(--inverted-bg-color);
}
