@mixin resets-block-styles {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@mixin reset-block-and-inner {
  @include resets-block-styles;
  * {
    @include resets-block-styles;
  }
}
