@import "src/styles/colors/colors.scss";

.borderedButton {
  width: 100%;
  background-color: transparent;
  color: $white;
  font-size: 18px;
  border: 1px solid transparent;
  padding: 18px;
  border-radius: 10px;
  border-color: var(--inverted-bg-color);
}
