@import "src/styles/colors/colors.scss";

$zIndexCarMarker: 5;
$zIndexPopup: 20;

.maplibre-map {
  width: 100%;
  height: 100%;

  .maplibregl-ctrl-top-left {
    z-index: 100;
    top: 150px;
  }
}

.location-button,
.map-navigation,
.view-3d-button,
.speed {
  position: absolute;
  z-index: 100;
  background-color: var(--main-bg-color);
  border-radius: 16px;
  border: none;
  outline: none;
}

.location-button {
  width: 44px;
  height: 44px;
  top: 100px;
  right: 16px;
  background-color: var(--inverted-bg-color);
  color: var(--main-bg-color);
}

.location-button-tracking {
  .location-image {
    fill: var(--main-bg-color);
    stroke: var(--main-bg-color);

    path {
      fill: var(--main-bg-color);
      stroke: var(--main-bg-color);
    }
  }
}

.location-image {
  fill: var(--main-bg-color);
  stroke: var(--main-bg-color);

  path {
    fill: var(--main-bg-color);
    stroke: var(--main-bg-color);
  }
}

.map-navigation {
  display: flex;
  background-color: $green-200;
  bottom: 25px;
  right: 72px;
  padding: 5px;
  width: 34px;
  height: 34px;
  border-radius: 50%;

  path {
    fill: $white;
  }

  &.active {
    path {
      fill: $yellow-500;
    }
  }
}

.view-3d-button {
  width: 44px;
  height: 44px;
  top: 100px;
  left: 16px;
  font-weight: 700;
  font-size: 20px;
  background-color: var(--inverted-bg-color);
  color: var(--main-bg-color);
}

.speed {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 16px;
  width: 60px;
  height: 54px;
  bottom: 15px;
  left: 16px;
  justify-content: center;
  align-items: center;
  color: var(--inverted-bg-color);
}

.car-marker,
.pickup-point-marker,
.pickupPointMarker {
  background-repeat: no-repeat;
  background-position: center;

  svg {
    display: none;
  }
}

.car-marker {
  background-image: url("../../images/green-navigation.svg");
  background-size: contain;
  width: 35px;
  height: 35px;
  z-index: $zIndexCarMarker;
}

.pickup-point-marker {
  background-image: url("../../images/map-pin-green.svg");
  background-size: contain;
  width: 33px;
  height: 40px;
}

.pickupPointMarker {
  background-image: url("../../images/MyGeolocation.svg");
  background-size: contain;
  display: flex;
  z-index: 16px;

  width: 45px;
  height: 72px;
}

.animateMarker {
  position: relative;
  transform-style: preserve-3d;
  animation: markerAnimation 0.3s ease-in-out forwards;
}

.animateMarker::after {
  content: "";
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, calc(-50% + 33px), -1px);
  width: 116px;
  height: 116px;
  border-radius: 50%;
  background-color: var(--marker-bottom-color);
  border: 1px solid var(--inverted-bg-color);
  transition: background-color 0.1s ease-in-out;
  margin: 0 auto;
}

@keyframes markerAnimation {
  0% {
    top: -7px;
  }

  100% {
    top: -7px;
  }
}

.blackPoint,
.grayPoint {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.pointBig {
  width: 32px;
  height: 32px;
}

.innerBlackPoint {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerPointBig {
  width: 18px;
  height: 18px;
}

.blackPoint {
  background-color: var(--inverted-bg-color);

  .innerBlackPoint {
    background-color: var(--main-bg-color);
    color: var(--inverted-bg-color);
  }
}

.grayPoint {
  background-color: var(--main-bg-color-secondary);

  .innerBlackPoint {
    background-color: var(--inverted-bg-color);
    color: var(--main-bg-color-secondary);
  }
}

.redPoint {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

// animation for radius
.pulse {
  animation: pulse-animation 2s infinite;
  /* Применение анимации к слою */
}

@keyframes pulse-animation {
  0% {
    fill-opacity: 0;
  }

  50% {
    fill-opacity: 0.7;
  }

  100% {
    fill-opacity: 0;
  }
}

// Styles for Popup
.custom-popup {
  border-radius: 50px;
  background-color: var(--inverted-bg-color);
  color: #000;
  z-index: $zIndexPopup;

  .popup-container {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding: 8px 0;

    .popup-address {
      margin-right: 50px;
      margin-left: 11px;

      span {
        font-family: "Roboto";
        font-size: 10px;
        letter-spacing: 0.4px;
        font-weight: 400;
        color: var(--inverted-text-color);
      }
    }

    .popup-circle {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 43px;
      height: 43px;
      background-color: var(--popup-circle);
      border-radius: 50%;

      .popup-distance {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          margin: -4px 0;
          font-family: "Roboto";
          font-weight: 500;
          font-size: 11px;
          letter-spacing: 0.4px;
          color: var(--text-color-1);
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 34px;
      left: 45%;
      transform: translateX(-50%);
      border-width: 8px 8px 0;
      border-style: solid;
      border-color: var(--inverted-bg-color) transparent transparent transparent;
    }
  }
}

// Remove default styles for popup
.maplibregl-popup-tip,
.maplibregl-popup-content {
  background: none;
  padding: 0;
  box-shadow: none;
  border: none;
}

// For Map in Modal
.orderMap {
  border-radius: 20px;
}