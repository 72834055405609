.bottomNavigation {
  [class~="MuiPaper-root"] {
    background-color: var(--main-bg-color);
  }

  [class~="MuiBottomNavigation-root"] {
    background-color: var(--main-bg-color);
    justify-content: space-around;
  }

  [class~="MuiButtonBase-root"] {
    background-color: var(--main-bg-color);
    color: var(--text-color) !important;
    font-family: "Roboto";
    font-weight: 400;
    line-height: 16px;
    min-width: 60px;
    letter-spacing: 0.5;

    svg {
      height: 23px;
      path {
        stroke-width: 0;
        fill: var(--icon-color-1);
        stroke: var(--icon-color-1);
      }
    }

    .travelsIcon {
      path {
        &:last-child {
          fill: var(--main-bg-color-secondary);
          stroke: var(--main-bg-color-secondary);
        }
      }
    }

    .etherIcon {
      path {
        stroke-width: 2px;
      }
    }
  }

  [class~="MuiBottomNavigationAction-label"] {
    font-size: 15px;
    padding-top: 10px;
  }

  [class~="Mui-selected"] {
    font-size: 15px !important;
    color: var(--informing-bg-1);

    svg {
      path {
        fill: var(--informing-bg-1);
        stroke: var(--informing-bg-1);
      }
      circle {
        fill: var(--informing-bg-1);
      }
    }
  }

  [class~="MuiBadge-badge"] {
    height: 18px;
    min-width: 17px;
    font-size: 11px;
  }
}

.isDisabledMenu {
  pointer-events: none;
  opacity: 0.8;
}
