/* удаление иконки отображения пароля */
input[type="password"]::-ms-reveal {
  display: none;
}

a {
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}
