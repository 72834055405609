.searchComponentWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  border-radius: 16px;

  [class~="MuiInputBase-root"] {
    padding: 0 10px;
  }
}

.searchComponentInput {
  width: 100%;
  height: 48px;
  padding: 16px 8px;
  background-color: var(--search-input-1);

  [class~="MuiInputBase-root"] {
    color: var(--inverted-bg-color);
  }
}

.confirmBtn {
  color: var(--button-start-work-text-color);
  padding: 16px;
}

.searchComponentPinImg {
  path:first-child {
    fill: var(--pin-1);
  }

  path:nth-child(2) {
    fill: var(--main-bg-color);
  }
}

.closeIcon {
  display: flex;
  width: 35px;
  height: 35px;

  path {
    fill: var(--inverted-bg-color);
    stroke: var(--inverted-bg-color);
  }
}
