.container {
  margin: 0 11px;
  padding: 20px;

  .closeBlock {
    display: flex;
    justify-content: end;

    .closeIcon {
      path {
        stroke: var(--inverted-bg-color);
      }
    }
  }

  h1 {
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.1px;
    text-align: center;
    color: var(--inverted-bg-color);
    padding-bottom: 30px;
  }


  h2 {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: var(--inverted-bg-color);
    margin-bottom: 15px;
  }
  
  li {
    padding: 15px 10px;
    border-radius: 8px;
    box-shadow: 0 0 7px var(--icon-color-1);
    margin: 20px 0;
  }

  .filterTitle{
    display: flex;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .carClassesFilter{
    display: flex;
  }

.confirmBlock {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  .confirmBtn {
    width: 100%;
    background-color: var(--button-bg-2);
    color: #ffffff;
  }

  .btnDisabled {
    color: var(--button-text-color-disabled);
    background-color: var(--text-disable-color);
  }
}
}